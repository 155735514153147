@keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
}


html, body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body {
    background: url(../assets/bg.jpg);
    background-size: 120% 120%;
    background-repeat: no-repeat;
    background-color: #008000;
    animation: animatedBackground 20s linear infinite alternate;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .info-area {
        border-radius: 10px;
        /* text-align: center; */
        font-family: Roboto, sans-serif;
        color: white;
        margin: 50px 10px;
        font-size: 35px;
        font-weight: 900;

        #form {
            & > div {
                margin: 10px 0;
            }

            input {
                height: 32px;
                width: 238px;
                border-radius: 5px;
                font-size: 20px;
                /* visibility: hidden; */
            }

            #errors {
                background: red;
                font-size: 10px;
            }
        }

    }
}

.hidden {
    display: none;
}

input[type="file"] {
    visibility: hidden;
    position: absolute;
}

label {
    font-size: 18px;
    background-color: white;
    padding: 10px;
    display: block;
    color: black;
}

video#backgroundVideoPlayArea {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: auto;
    z-index: -2;
}

@media only screen and  (min-width: 1001px) {
    .container {
        flex-direction: row;
    }
}